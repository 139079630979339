<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">一人一档列表</a>
         
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <!-- <div title="名称" class="searchboxItem ci-full">
              <span class="itemLabel">名称:</span>
              <el-input
                v-model="enterpriseName"
                type="text"
                size="small"
                placeholder="请输入名称名称"
                clearable
              />
            </div> -->
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                placeholder="请输入企业名称"
                clearable
              />
            </div>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="班级编码"
                align="left"
                prop="projectCode"
                min-width="180"
                show-overflow-tooltip
              />
              <el-table-column
                label="班级名称"
                align="left"
                prop="projectName"
                min-width="180"
                show-overflow-tooltip
              />
              <el-table-column
                label="企业名称"
                align="left"
                prop="compName"
                width="260"
                show-overflow-tooltip
              />
              <el-table-column
                label="行政区划"
                align="left"
                prop="areaNamePath"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="培训类型"
                align="left"
                prop="trainTypeName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="行业类型"
                align="left"
                prop="industryName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="岗位类型"
                align="left"
                prop="postName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="职业工种"
                align="left"
                prop="occupationName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="培训等级"
                align="left"
                prop="trainLevelName"
                width="150"
                show-overflow-tooltip
              />
              <el-table-column
                label="操作"
                align="center"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                 
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handStudentFile(
                       scope.row.projectId
                      )
                    "
                    >培训档案</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "OnepersonOneFileJg",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
    projectName:'', //班级名称
      compName: "", //题库名称
    };
  },
  created() {

  },
 computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      this.doFetch(
        {
          url: "/eighteenEnterprise/enterpriseUser/pageList",
          params,
          pageNum,
        }
      );
    },
    /* 学员档案 */
    handStudentFile(projectId) {
        this.$router.push({
            path:'/web/OnepersonStudentFilesJg',
            query:{
                projectId,
            }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
           this.getData();
        }
        // if (oldVal.query.stu == "add") {
        //   (this.recommendName = ""),
        //     (this.areaId = ""),
        //     (this.terminals = ""),
        //     (this.pageNum = 1);
        //   this.getData(-1);
        // }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.downloadStyle {
    background-color: #f0f5fb;
    padding: 20px 0;
    margin-bottom: 30px;
    overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.el-checkbox {
    display: flex;
    white-space: pre-wrap !important;
    text-align: justify;
}</style>
